
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.articles {
  .tile-grid {
    grid-template-columns: 1fr 1fr 1fr;

    @include for-size(phone-portrait-down) {
      grid-template-columns: 1fr;
    }
  }
}
